import React from 'react';

import PropTypes from 'prop-types';
import { InferProps } from 'prop-types';

import classNames from 'classnames';

export enum Crop {
	SUPER_LARGE = '2048x2048',
	EXTRA_LARGE = '1536x1536',
	LARGE = 'large',
	MEDIUM = 'medium',
	MEDIUM_LARGE = 'medium_large',
	THUMBNAIL = 'thumbnail',
}

const propTypes = {
	noResponsive: PropTypes.bool,
	lazy: PropTypes.bool,
	className: PropTypes.string,

	srcSet: PropTypes.string,
	sizes: PropTypes.string,

	block: PropTypes.shape({
		content: PropTypes.shape({
			// For backwards compatibility, either can be used. `src` takes priority
			src: PropTypes.string,
			url: PropTypes.string,

			alt: PropTypes.string,
			className: PropTypes.string,

			width: PropTypes.number,
			height: PropTypes.number,

			crop: function (props, propName, componentName) {
				const prop = props[propName] as unknown;

				const validCrops = Object.values(Crop);
				if ((validCrops as unknown[]).includes(prop)) {
					return null;
				} else if (
					prop === null ||
					typeof prop === 'undefined'
				) {
					return null;
				} else {
					return new Error(`Invalid prop ${propName} supplied to ${componentName}. Unrecognised crop ${JSON.stringify(prop, null, '\t')}`);
				}
			} as PropTypes.Validator<Crop | null | undefined>,

			// I'd like to define this with respect to `Crop`, but I don't think it's worth the effort
			sizes: PropTypes.shape({
				['1536x1536']: PropTypes.string,
				['1536x1536-height']: PropTypes.number,
				['1536x1536-width']: PropTypes.number,

				['2048x2048']: PropTypes.string,
				['2048x2048-height']: PropTypes.number,
				['2048x2048-width']: PropTypes.number,

				large: PropTypes.string,
				['large-height']: PropTypes.number,
				['large-width']: PropTypes.number,

				medium: PropTypes.string,
				['medium-height']: PropTypes.number,
				['medium-width']: PropTypes.number,

				medium_large: PropTypes.string,
				['medium_large-height']: PropTypes.number,
				['medium_large-width']: PropTypes.number,

				thumbnail: PropTypes.string,
				['thumbnail-height']: PropTypes.number,
				['thumbnail-width']: PropTypes.number,
			}),
		}),
	}),
};
type ImageProps = InferProps<typeof propTypes>;

const defaultProps = {
	noResponsive: false,
	lazy: false,
	className: '',
};

const Image = Object.assign(
	function Image(props: ImageProps) {
		const content = props.block?.content;
		let src = content?.src ?? content?.url;

		const noResponsive = props.noResponsive ?? defaultProps.noResponsive;
		const lazy = props.lazy ?? defaultProps.lazy;

		const className = classNames(
			props.className ?? defaultProps.className,
			content?.className,
			{
				['img-responsive']: !noResponsive,
			},
		);

		const { srcSet, sizes } = props;

		if (!(content && src)) {
			return null;
		}

		if (srcSet && sizes) {
			const { width, height } = content;
			const aspectRatio = `${width} / ${height}`;

			return (
				<img
					className={className}
					src={src}
					alt={content.alt ?? ''}
					loading={lazy ? 'lazy' : undefined}
					style={{
						aspectRatio,
					}}

					srcSet={srcSet}
					sizes={sizes}
				/>
			);
		}

		let aspectRatio = '';
		const { crop } = content;
		if (
			content.sizes &&
			crop
		 ) {
			const cropSrc = content.sizes[crop];
			const cropWidth = content.sizes[`${crop}-width`];
			const cropHeight = content.sizes[`${crop}-height`];

			if (cropSrc && cropWidth && cropHeight) {
				src = cropSrc;
				aspectRatio = `${cropWidth} / ${cropHeight}`;
			}
		 }

		if (!aspectRatio) {
			const { width, height } = content;
			aspectRatio = `${width} / ${height}`;
		}

		return (
			<img
				className={className}
				src={src}
				alt={content.alt ?? ''}
				loading={lazy ? 'lazy' : undefined}
				style={{
					aspectRatio,
				}}
			/>
		);
	},
	{
		propTypes,
		defaultProps,
	},
);

export default Image;
