import React, { useState } from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import getAcfBlockProps from 'components/prop-types/acf-block';

import Link from 'next/link';
import Image, { Crop } from './image';

const propTypes = {
	...getAcfBlockProps(
		{
			title: PropTypes.string,
			items: PropTypes.arrayOf(PropTypes.shape({
				image: PropTypes.shape({
					url: PropTypes.string.isRequired,
					alt: PropTypes.string,

					width: PropTypes.number,
					height: PropTypes.number,

					sizes: PropTypes.shape({
						thumbnail: PropTypes.string.isRequired,
						['thumbnail-width']: PropTypes.number.isRequired,
						['thumbnail-height']: PropTypes.number.isRequired,
					}),
				}).isRequired,
				link: PropTypes.shape({
					url: PropTypes.string.isRequired,
					text: PropTypes.string.isRequired,
				}).isRequired,
			}).isRequired).isRequired,
		}
	),
};
type IndustryTilesPropTypes = InferProps<typeof propTypes>;

const IndustryTiles = Object.assign(
	function IndustryTiles(props: IndustryTilesPropTypes) {
		const content = props.block?.content;

		const [isFarLeft, setIsFarLeft] = useState(true);
		const [isFarRight, setIsFarRight] = useState(false);

		const listRef = React.createRef<HTMLUListElement>();

		function scrollListBy(by: number): void {
			const list = listRef.current;
			if (list) {
				const childExample = list.children[0] as HTMLLIElement;
				if (childExample) {
					const childWidth = childExample.getBoundingClientRect().width;

					list.scrollBy(childWidth * by, 0);
				}
			}
		}

		function scrollListLeft(e: React.MouseEvent<HTMLButtonElement>): void {
			scrollListBy(-1);
		}

		function scrollListRight(e: React.MouseEvent<HTMLButtonElement>): void {
			scrollListBy(+1);
		}

		function scrollHandler(e: React.UIEvent<HTMLUListElement>): void {
			const threshold = 5;

			const listEl = e.currentTarget;
			const listWidth = listEl.getBoundingClientRect().width;

			const { scrollLeft, scrollWidth } = listEl;

			const leftGap = scrollLeft;
			const rightGap = scrollWidth - (scrollLeft + listWidth);

			const scrolledFarLeft = leftGap < threshold;
			setIsFarLeft(scrolledFarLeft);

			const scrolledFarRight = rightGap < threshold;
			setIsFarRight(scrolledFarRight);
		}

		if (!content) {
			return null;
		}

		return (
			<div className="container">
				{
					content.title &&
					<h2 className="industry-tiles__title">{content.title}</h2>
				}
				<div className="industry-tiles__carousel">
					<div className="industry-tiles__controls">
						<button
							type="button"
							className="industry-tiles__control"
							onClick={scrollListLeft}
							aria-label="Previous"
							disabled={isFarLeft}
						>
							<span className="industry-tiles__control__icon" aria-hidden="true"></span>
						</button>
						<button
							type="button"
							className="industry-tiles__control"
							onClick={scrollListRight}
							aria-label="Next"
							disabled={isFarRight}
						>
							<span className="industry-tiles__control__icon" aria-hidden="true"></span>
						</button>
					</div>
					<ul
						className="industry-tiles__items"
						ref={listRef}
						onScroll={scrollHandler}
					>
						{content.items.map((item, i) => {
							// If the image is too large, crop it
							const maxSize = 300;
							const { width, height } = item.image;
							const minImageDim = Math.min(width ?? Infinity, height ?? Infinity);
							const crop = minImageDim >= maxSize ? Crop.THUMBNAIL : undefined;

							return <li
								key={i}
								className="industry-tiles__item"
							>
								<Link href={item.link.url}>
									<a className="industry-tiles__item__link no-color-override">
											<div className="industry-tiles__item__figure">
											<Image
												className="industry-tiles__item__image"
												lazy={true}
												block={{
													content: {
														...item.image,
														crop,
													},
												}}
											/>
										</div>
										<span className="industry-tiles__item__title">{item.link.text}</span>
									</a>
								</Link>
							</li>;
						})}
					</ul>
				</div>
			</div>
		);
	},

	{ propTypes },
);

export default IndustryTiles;
